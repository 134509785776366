import hol from "../../img/Botev/hol.jpg"
import hol1 from "../../img/Botev/hol1.jpg"
import hol2 from "../../img/Botev/hol3.jpg"
import hol3 from "../../img/Botev/hol4.jpg"
import terasa from "../../img/Botev/terasa.jpg"
import terasa1 from "../../img/Botev/terasa1.jpg"
import spalnq from "../../img/Botev/spalnq.jpg"
import spalnq1 from "../../img/Botev/spalnq1.jpg"
import banq from "../../img/Botev/banq.jpg"

export const BotevPhotos = [
    {
        image: spalnq,
        alt: "spalnq"
    },
    {
        image: spalnq1,
        alt: "spalnq"
    },
    {
        image: hol3,
        alt: "hol"
    },
    {
        image: terasa,
        alt: "terasa"
    },
    
    {
        image: hol1,
        alt: "hol"
    },
    {
        image: hol2,
        alt: "hol"
    },
    {
        image: terasa1,
        alt: "terasa"
    },
    {
        image: banq,
        alt: "banq"
    },
    {
        image: hol,
        alt: "hol"
    },
]